<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 bg-white">
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        {{ $t("DetallesSubCliente") }}
      </h2>
    </div>
    <!-- client data -->
    <b-row v-if="userPrepare">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-3">
            <b-col xl="5" md="8" class="d-flex align-items-center">
              <div class="pr-2">
                <b-avatar size="6rem" :src="client.image.path" />
              </div>
              <div>
                <h4 class="m-0 font-weight-bolder">
                  {{ client.name }}
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row class="position-relative">
            <b-col xl="3" md="6" v-if="client.legal_name && client.legal_name != 'undefined'">
              <p class="mb-50 title-style text-uppercase">
                {{ $t("LegalName") }}
              </p>
              <p class="font-weight-bolder">
                <span>{{ client.legal_name }}</span>
              </p>
            </b-col>
            <b-col xl="3" md="6" v-if="client.cif && client.cif != 'undefined'">
              <p class="mb-50 title-style text-uppercase">
                {{ $t("CIF") }}
              </p>
              <p class="font-weight-bolder">
                <span>{{ client.cif }}</span>
              </p>
            </b-col>

            <b-col xl="3" md="6" v-if="client.email && client.email != 'undefined'">
              <p class="mb-50 title-style">
                {{ $t("EMAIL") }}
              </p>
              <p class="font-weight-bolder">
                {{ client.email }}
              </p>
            </b-col>
            <b-col xl="3" md="6" v-if="client.phone && client.phone != 'undefined'">
              <p class="mb-50 title-style">
                {{ $t("TELEFONO") }}
              </p>
              <p class="font-weight-bolder">
                {{ client.phone }}
              </p>
            </b-col>
            <b-col xl="3" sm="4" v-if="client.province">
              <p class="mb-50 title-style">
                {{ $t("Provincia") }}
              </p>
              <p class="font-weight-bolder">
                {{ client.province["Provincia"] }}
              </p>
            </b-col>
            <b-col xl="3" sm="4" v-if="client.town">
              <p class="mb-50 title-style">
                {{ $t("Municipio") }}
              </p>
              <p class="font-weight-bolder">
                {{ client.town["Municipio"] }}
              </p>
            </b-col>
            <b-col xl="3" sm="4" v-if="client.postalCode && client.postalCode != 'undefined'">
              <p class="mb-50 title-style">
                {{ $t("CodigoPostal") }}
              </p>
              <p class="font-weight-bolder">
                {{ client.postalCode }}
              </p>
            </b-col>
            <b-col cols="12" class="text-right">
              <b-link :to="{ name: 'editSubClient', params: { id: client.id } }" class="d-inline-block text-dark pt-2">
                <span class="">{{ $t("Editar") }}</span>
                <span class="pl-1"><feather-icon icon="Edit2Icon" /></span>
              </b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-tabs nav-class="nav-left nav-pills custom-pill mt-2 mb-1">
      <b-tab active>
        <template #title class="">
          <feather-icon icon="LayersIcon" size="18" class="mr-50" />
          <span class="font-weight-bold"> {{ $t("Activos") }}</span>
        </template>

        <ListAssets :userprop="client.id" :subclient_id="id" :hidden="true" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" size="18" class="mr-50" />
          <span class="font-weight-bold"> {{ $t("ContactPersons") }}</span>
        </template>
        <ContactPersonTable :subclient="id" />

      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCard,
  BLink,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ListAssets from "@/views/installation/ListTable";

import ContactPersonTable from "@/components/contactPerson/table/ContactPersonTable.vue";

export default {
  components: {
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BLink,
    ListAssets,
    BTabs,
    BTab,

    ContactPersonTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      // companies: "",
      // departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      userPrepare: false,
      changePassword: false,
      collapseInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      client: "subclients/getSubClient",

      // role: "auth/getRole",
    }),
    canRestartJourney() {
      if (this.checkJourney.length && this.checkJourney[0].hour_fin !== null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getSubClient: "subclients/getSubClient",
    }),
    ...mapMutations({
      setSelectDepartments: "departments/setSelectDepartments",
    }),
  },
  async created() {
    await this.getSubClient(this.$route.params.id);
    this.userPrepare = true;
  },
  destroyed() {
    this.$store.commit("subclients/setSubClient", {});
  },
};
</script>
